.sidebar
	+flexCol()
	align-items: center
	position: relative
	padding: 1.6rem 0 1rem 0
	width: $sidebarWidth
	background-color: $sidebar
	flex-shrink: 0
	&--team
		padding-top: 1.6rem
		box-shadow: inset 0 0 5rem $black10
		.sidebar__main__list
			display: flex
			flex-direction: column
			gap: 1.3rem
	&__header
		+flexCol()
		+trans()
		align-items: center
		flex-shrink: 0
		padding-bottom: 2.4rem
		&__logo
			+flexAxis()
			+sq(2.8)
			cursor: pointer
			flex-shrink: 0
		&__tasks
			margin-top: 1rem
		&__dashboard
			margin: 0 0 3rem 0
	&__main
		flex-grow: 1
		height: 100%
		overflow-x: hidden
		overflow-y: auto
	&__footer
		&__list
			display: block
			&__item
				+flexAxis()
				&:last-child
					margin-top: 1rem
					margin-bottom: 1rem
		.avatar
			+avatar(2.6rem, 0, $dark)

@media screen and (max-height: 650px)
	.sidebar
		&__header
			padding-bottom: 3rem
		&__main
			&__list
				&__item
					&:last-child
						margin-top: 0
@media screen and (max-height: 545px)
	.sidebar
		&__footer
			border-top: .1rem solid $black10
