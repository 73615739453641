.webhook
	&__wrapper
		+fullwh()
		+flexCol()
	&__header
		+flexSpread()
		max-height: $listHeight
		height: 100%
		flex-shrink: 0
		border-bottom: .1rem solid $border
		padding: 0 $boardPad
	&__list
		height: 100%
		&-container
			height: 100%
			padding: $boardPad
			background-color: $black3
			overflow-y: auto
	&__item
		+br()
		display: flex
		padding: $boardPad
		border: .1rem solid $border
		gap: $boardPad
		background-color: $white
		margin-bottom: 2rem
		&--fake
			span
				+br(5)
				display: inline-block
				min-width: 1rem
				min-height: 1rem
				background-color: $black6
			.webhook
				&__created
					span
						min-width: 15rem
				&__name
					span
						background-color: $black16
						min-height: 1.8rem
						min-width: 26rem
				&__url
					span
						min-width: 46rem
				&__trigger
					span
						min-width: 10rem
					&__item
						border-color: $black10
						min-height: 3.2rem
				&__actions
					span
						width: 3rem
						height: 3rem
				span.fake-btn
					max-width: 17rem
	&__info
		+flexCol()
		flex-grow: 1
		gap: 1rem
		.btn
			align-self: flex-start
	&__actions
		flex-shrink: 0
		display: flex
		align-items: flex-start
		gap: 1rem
	&__created
		font-size: 1.1rem
		text-transform: uppercase
	&__name
		+bold()
		color: $black
		font-size: 1.8rem
		margin-bottom: 1rem
	&__url
		+br()
		color: $black
		background-color: $black2
		border: .1rem solid $black10
		padding: 1rem 2rem
	&__trigger
		+flex()
		gap: 1rem
		color: $black
		&__item
			+flex()
			+br()
			gap: 1rem
			padding: .6rem 1rem
			border: .1rem solid $black40
			align-self: self-start
			.pipeline-name
				+ellipsis()
				max-width: 26rem
			&__path
				+flex()
				max-width: 45rem
				gap: .4rem
