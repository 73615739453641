html
	font-size: 62.5%
	width: 100%
	height: 100%
	-moz-text-size-adjust: 100%
	-webkit-text-size-adjust: 100%
	-ms-text-size-adjust: 100%
	scroll-behavior: smooth
body
	width: 100%
	height: 100%

::-webkit-scrollbar
	height: .4rem
	width: .4rem
	background: #F9F9F9
::-webkit-scrollbar-thumb
	background: $black7
::-webkit-scrollbar-corner
	background: $black7

.leads-in-list .connections--list::-webkit-scrollbar,
.react-trello-board::-webkit-scrollbar,
.campaign__config-wrapper.for-pipelines::-webkit-scrollbar
	height: 1.4rem
	width: 1.4rem
	background: #F1F1F1
.leads-in-list .connections--list::-webkit-scrollbar-thumb,
.react-trello-board::-webkit-scrollbar-thumb,
.campaign__config-wrapper.for-pipelines::-webkit-scrollbar
	background: $black12
.leads-in-list .connectons--list::-webkit-scrollbar-corner,
.react-trello-board::-webkit-scrollbar-thumb,
.campaign__config-wrapper.for-pipelines::-webkit-scrollbar
	background: $black12
body,
input,
select,
textarea
	+font()

pre,
code
	box-sizing: border-box
	font-size: $txt - 0.2

a:not(.btn)
	+trans()
	color: $blue
	cursor: pointer
	&:hover
		text-decoration: underline
		text-decoration: underline
	+focus()

h1
	+bold()
	font-size: $title
	line-height: $title * 1.6
	color: $black
h2
	+bold()
	font-size: $title2
	line-height: $title2 * 1.6
	color: $black

h3
	+bold()
	font-size: $title3
	line-height: $title3 * 1.6
	color: $black

h4
	+bold()
	font-size: $title4
	line-height: $title4 * 1.6
	color: $black

h5
	font-size: 1rem
	+regular()
	color: $dark
	text-transform: uppercase
#root
	height: 100%
	width: 100%
	display: flex
	flex-direction: column

[role=button]
	cursor: pointer

.wrapper
	display: flex
	height: 100%
	overflow: hidden
	width: 100%

.avatar
	+avatar()
.team-avatar
	+flex()

img
	display: block
	max-width: 100%

dt
	+bold()
	font-size: 2.4rem
	color: $black
	margin-bottom: 2rem
dd
	margin-bottom: 6rem

$margin: 1.4rem

.mbi
	> :not(:last-child)
		margin-right: $margin
	&--small
		> :not(:last-child)
			margin-right: $margin - .4
	&--large
		> :not(:last-child)
			margin-right: $margin + .8
.mbib
	> :not(:last-child)
		margin-bottom: $margin
	&--small
		> :not(:last-child)
			margin-bottom: $margin - .4
	&--large
		> :not(:last-child)
			margin-bottom: $margin + .8
.fs-large
	font-size: 1.5rem
	line-height: 1.4
.no-wrap
	white-space: nowrap
.no-shrink
	flex-shrink: 0
.fs-normal
	font-size: 1.3rem !important
	line-height: 1.4
.account-badge
	+flexAxis()
	+br()
	+sq(2.4)
	background-color: #fff
	border: .1rem solid $dark10
	.icon
		stroke: $dark50

.chrome-extension-button,
.chrome-extension-delete
	max-width: 30rem
	width: 100%
	flex-shrink: 0
	cursor: pointer
	margin: auto
.message-preview,
.exclude-members
	.modal
		&__body
			+flexCol()
			flex-shrink: 1
	&__item
		max-height: 35rem
		height: 100%
		overflow-y: auto
		color: $black
.message-preview
	&__item
		+br()
		padding: 2rem
		background: #e1fef5
		border: .1rem solid #addacc
.exclude-members
	&__item
		li
			+flexSpread()
			+br()
			gap: 2rem
			border: .1rem solid $black10
			padding: 1rem 2rem
			background-color: $black3
			&:not(:last-child)
				margin-bottom: 1rem
.gap
	gap: 2rem
	&--3
		gap: .3rem
	&--4
		gap: .4rem
	&--5
		gap: .5rem
	&--6
		gap: .6rem
	&--10
		gap: 1rem
	&--20
		gap: 2rem
	&--30
		gap: 3rem
.flex
	+flex()
.flex-col
	display: flex
	flex-direction: column
.flex-bottom
	+flex()
	align-items: flex-end
.flex-spread
	+flexSpread()
.flex-axis
	+flexAxis()
.flex-right
	+flex()
	justify-content: flex-end
.flex-grow
	flex-grow: 1
.fullw
	width: 100%
	min-width: 0
.fullh
	height: 100%
	min-height: 0
.fullwh
	height: 100%
	width: 100%
	min-width: 0
	min-height: 0
.overflow
	overflow: visible !important
.underline
	text-decoration: underline
.ellipsis
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis
.no-ellipsis
	white-space: normal
	overflow: auto
	text-overflow: unset
	.flash-content
		white-space: normal
		overflow: auto
		text-overflow: unset
.center
	text-align: center
.blue
	color: $blue !important
	.icon
		stroke: $blue
.yellow
	color: $yellow !important
.yellowDark
	color: #cc9c00

a.black
	color: $black
	text-decoration: underline
	text-decoration: underline
.red
	color: $red !important
	.icon
		stroke: $red !important
.green
	color: $green-sf !important
	.icon
		stroke: $green-sf !important
.orange
	color: $orange !important
	.icon
		stroke: $orange !important
.dark
	color: $dark
	.icon
		stroke: $dark
.black
	color: $black!important
	.icon
		stroke: $black !important
.pink
	color: $pink!important
	.icon
		stroke: $pink !important
.new-version
	+flex()
	+mbi(2)
	flex-shrink: 0
	.label
		+br()
		+bold()
		cursor: default !important
		background-color: $white40
		color: $black !important
		margin-right: 2rem !important
.sponsor
	color: $sponsor
.relation
	color: $relation
.lead-relation
	color: $leadRelation
.contact
	color: $contact
.lead-contact
	color: $leadContact

.status-relation
	color: $relation
	&.status-lead
		color: $leadRelation
.status-contact
	color: $contact
	&.status-lead
		color: $leadContact
.connection-status
	+flex()
	width: 100%
	min-width: 0
	flex-shrink: 1
.br
	+br()
	overflow: hidden
.status-badge
	+flex()
	+ellipsis()
	+br(5)
	flex-shrink: 0
	width: 2rem
	height: 2rem
	margin-right: 1rem
	&.status-relation
		background-color: $relation
		&.status-lead
			background-color: $leadRelation
	&.status-contact
		background-color: $contact
		&.status-lead
			background-color: $leadContact
		&.status-sponsor
			background-color: $sponsorRelation
.bold,
strong
	+bold()
.normal
	font-weight: 400 !important
@keyframes process
	from
		transform: rotate(0deg)
	to
		transform: rotate(360deg)
.enriched-profile
	+flexAxis()
	+sq(1.4)
	+br(2)
	flex-shrink: 0
	border: .1rem solid $blue
	margin-right: .4rem
	.icon
		stroke: $blue !important
		stroke-width: .3rem
.beta-message
	+mbi()
	+bold
	display: flex
	align-items: flex-start
	color: $dark
	margin-bottom: 5rem
.beta-label
	+br()
	background-color: $pink
	color: #fff
	text-shadow: .1rem .1rem 0 $black10
	font-size: 1.4rem
	text-transform: uppercase
	padding: 1rem
.enrich-process
	+flexAxis()
	+mbi(.25)
	margin: auto
	color: $blue
	font-size: $txtSmall
	.icon
		stroke-width: .2rem
.cursor-default
	cursor: default
.inmail
	+flex()
	+bold()
	+textShadow()
	height: 100%
	background-color: $openprofile
	color: $white
	padding: 0 2rem
.quota
	+mbi(.3)
	+flexAxis()
	+bold()
	+br(.3)
	font-size: 1rem
	color: $white
	background-color: $red
	padding: .2rem .4rem
	margin: auto
	.icon
		stroke: $white
		stroke-width: .3rem
	&.daily
		background-color: #4f84cd
	&.queued
		background-color: #4fcd88
	&.subscription
		background-color: $yellow
		color: $black
		.icon
			stroke: $black
			stroke-width: .2rem
.skill
	+flexBtn()
	+br()
	background-color: $white
	height: 2.8rem
	font-size: $buttonFontSize
	line-height: 1.6
	padding: 0 1rem
	color: $black
	margin: .2rem 0
	max-width: 100%
	border: .1rem solid $black10
.btn
	&.new-folder
		max-width: 8rem !important
.status
	+br()
	flex-shrink: 0
	padding: .4rem .8rem
	font-size: 1.2rem
	font-weight: 600
	line-height: 1.2
	color: $white
	.list &
		display: inline-flex
	&--red
		background-color: $red
		text-shadow: .1rem .1rem 0 $black20
	&--yellow
		background-color: $yellow
		color: $black
	&--orange
		background-color: $orange
		color: $black
	&--green
		color: $black
		background-color: #88ff39
	&--processing
		color: $black
		background-color: $processing
svg
	&:focus
		outline: none !important
		border: 0 !important
		box-shadow: none !important
	*
		&:focus
			outline: none !important
			border: 0 !important
			box-shadow: none !important

.react-datepicker
	+br()
	font-size: 100%
	min-width: 100%
	max-width: 100%
	font-family: "Mulish", sans-serif
	border-color: $black20
	*
		font-size: 100%
	&-wrapper,
	&__input-container,
	&-popper,
	&__month-container
		width: 100%
		min-width: 100%
		max-width: 100%
	&-popper[data-placement^=bottom]
		.react-datepicker__triangle
			stroke: $black20
			fill: #dee9ff
			color: #dee9ff
	&__header
		background-color: $blue16
		border-color: $black10
		border-radius: .5rem .5rem 0 0
	&__day-names
		margin: 0
	&__month
		margin: 0.8rem
	&__day-name,
	&__day,
	&__time-name
		margin: 0.3rem
		width: 2.6rem
		height: 2.6rem
		line-height: 2.6rem
	&__day--keyboard-selected,
	&__month-text--keyboard-selected,
	&__quarter-text--keyboard-selected,
	&__year-text--keyboard-selected
		background-color: $blue30
		color: $black
	&__day
		&--selected
			+bold()
			background-color: $red
			color: $white
		&--today
			background-color: $blue
			color: $white
			border-radius: .3rem
			&.react-datepicker__day--selected
				background-color: $red
				color: $white

	&__day,
	&__month-text,
	&__quarter-text,
	&__year-text
		&:hover
			background-color: $blue
			color: $white

	&__year-read-view--down-arrow,
	&__month-read-view--down-arrow,
	&__month-year-read-view--down-arrow,
	&__navigation-icon::before
		width: .6rem
		height: .6rem
		border-color: $black
		border-width: .15rem .15rem 0 0
// margins
.mt
	&--100
		margin-top: 10rem !important
	&--95
		margin-top: 9.5rem !important
	&--90
		margin-top: 9rem !important
	&--85
		margin-top: 8.5rem !important
	&--80
		margin-top: 8rem !important
	&--75
		margin-top: 7.5rem !important
	&--70
		margin-top: 7rem !important
	&--65
		margin-top: 6.5rem !important
	&--60
		margin-top: 6rem !important
	&--55
		margin-top: 5.5rem !important
	&--50
		margin-top: 5rem !important
	&--45
		margin-top: 4.5rem !important
	&--40
		margin-top: 4rem !important
	&--35
		margin-top: 3.5rem !important
	&--30
		margin-top: 3rem !important
	&--25
		margin-top: 2.5rem !important
	&--20
		margin-top: 2rem !important
	&--15
		margin-top: 1.5rem !important
	&--10
		margin-top: 1rem !important
	&--5
		margin-top: .5rem !important
	&--0
		margin-top: 0 !important
.mb
	&--100
		margin-bottom: 10rem !important
	&--95
		margin-bottom: 9.5rem !important
	&--90
		margin-bottom: 9rem !important
	&--85
		margin-bottom: 8.5rem !important
	&--80
		margin-bottom: 8rem !important
	&--75
		margin-bottom: 7.5rem !important
	&--70
		margin-bottom: 7rem !important
	&--65
		margin-bottom: 6.5rem !important
	&--60
		margin-bottom: 6rem !important
	&--55
		margin-bottom: 5.5rem !important
	&--50
		margin-bottom: 5rem !important
	&--45
		margin-bottom: 4.5rem !important
	&--40
		margin-bottom: 4rem !important
	&--35
		margin-bottom: 3.5rem !important
	&--30
		margin-bottom: 3rem !important
	&--25
		margin-bottom: 2.5rem !important
	&--20
		margin-bottom: 2rem !important
	&--15
		margin-bottom: 1.5rem !important
	&--10
		margin-bottom: 1rem !important
	&--5
		margin-bottom: .5rem !important
	&--0
		margin-bottom: 0 !important

.pt
	&--100
		padding-top: 10rem !important
	&--95
		padding-top: 9.5rem !important
	&--90
		padding-top: 9rem !important
	&--85
		padding-top: 8.5rem !important
	&--80
		padding-top: 8rem !important
	&--75
		padding-top: 7.5rem !important
	&--70
		padding-top: 7rem !important
	&--65
		padding-top: 6.5rem !important
	&--60
		padding-top: 6rem !important
	&--55
		padding-top: 5.5rem !important
	&--50
		padding-top: 5rem !important
	&--45
		padding-top: 4.5rem !important
	&--40
		padding-top: 4rem !important
	&--35
		padding-top: 3.5rem !important
	&--30
		padding-top: 3rem !important
	&--25
		padding-top: 2.5rem !important
	&--20
		padding-top: 2rem !important
	&--15
		padding-top: 1.5rem !important
	&--10
		padding-top: 1rem !important
	&--5
		padding-top: .5rem !important
	&--0
		padding-top: 0 !important
.pb
	&--100
		padding-bottom: 10rem !important
	&--95
		padding-bottom: 9.5rem !important
	&--90
		padding-bottom: 9rem !important
	&--85
		padding-bottom: 8.5rem !important
	&--80
		padding-bottom: 8rem !important
	&--75
		padding-bottom: 7.5rem !important
	&--70
		padding-bottom: 7rem !important
	&--65
		padding-bottom: 6.5rem !important
	&--60
		padding-bottom: 6rem !important
	&--55
		padding-bottom: 5.5rem !important
	&--50
		padding-bottom: 5rem !important
	&--45
		padding-bottom: 4.5rem !important
	&--40
		padding-bottom: 4rem !important
	&--35
		padding-bottom: 3.5rem !important
	&--30
		padding-bottom: 3rem !important
	&--25
		padding-bottom: 2.5rem !important
	&--20
		padding-bottom: 2rem !important
	&--15
		padding-bottom: 1.5rem !important
	&--10
		padding-bottom: 1rem !important
	&--5
		padding-bottom: .5rem !important
	&--0
		padding-bottom: 0 !important

.pr
	&--100
		padding-right: 10rem !important
	&--95
		padding-right: 9.5rem !important
	&--90
		padding-right: 9rem !important
	&--85
		padding-right: 8.5rem !important
	&--80
		padding-right: 8rem !important
	&--75
		padding-right: 7.5rem !important
	&--70
		padding-right: 7rem !important
	&--65
		padding-right: 6.5rem !important
	&--60
		padding-right: 6rem !important
	&--55
		padding-right: 5.5rem !important
	&--50
		padding-right: 5rem !important
	&--45
		padding-right: 4.5rem !important
	&--40
		padding-right: 4rem !important
	&--35
		padding-right: 3.5rem !important
	&--30
		padding-right: 3rem !important
	&--25
		padding-right: 2.5rem !important
	&--20
		padding-right: 2rem !important
	&--15
		padding-right: 1.5rem !important
	&--10
		padding-right: 1rem !important
	&--5
		padding-right: .5rem !important
	&--0
		padding-right: 0 !important
.pl
	&--100
		padding-left: 10rem !important
	&--95
		padding-left: 9.5rem !important
	&--90
		padding-left: 9rem !important
	&--85
		padding-left: 8.5rem !important
	&--80
		padding-left: 8rem !important
	&--75
		padding-left: 7.5rem !important
	&--70
		padding-left: 7rem !important
	&--65
		padding-left: 6.5rem !important
	&--60
		padding-left: 6rem !important
	&--55
		padding-left: 5.5rem !important
	&--50
		padding-left: 5rem !important
	&--45
		padding-left: 4.5rem !important
	&--40
		padding-left: 4rem !important
	&--35
		padding-left: 3.5rem !important
	&--30
		padding-left: 3rem !important
	&--25
		padding-left: 2.5rem !important
	&--20
		padding-left: 2rem !important
	&--15
		padding-left: 1.5rem !important
	&--10
		padding-left: 1rem !important
	&--5
		padding-left: .5rem !important
	&--0
		padding-left: 0 !important
.my
	&--100
		margin: 10rem 0 !important
	&--95
		margin: 9.5rem 0 !important
	&--90
		margin: 9rem 0 !important
	&--85
		margin: 8.5rem 0 !important
	&--80
		margin: 8rem 0 !important
	&--75
		margin: 7.5rem 0 !important
	&--70
		margin: 7rem 0 !important
	&--65
		margin: 6.5rem 0 !important
	&--60
		margin: 6rem 0 !important
	&--55
		margin: 5.5rem 0 !important
	&--50
		margin: 5rem 0 !important
	&--45
		margin: 4.5rem 0 !important
	&--40
		margin: 4rem 0 !important
	&--35
		margin: 3.5rem 0 !important
	&--30
		margin: 3rem 0 !important
	&--25
		margin: 2.5rem 0 !important
	&--20
		margin: 2rem 0 !important
	&--15
		margin: 1.5rem 0 !important
	&--10
		margin: 1rem 0 !important
	&--5
		margin: .5rem 0 !important

.px
	&--100
		padding: 0 10rem 0 !important
	&--95
		padding: 0 9.5rem !important
	&--90
		padding: 0 9rem !important
	&--85
		padding: 0 8.5rem !important
	&--80
		padding: 0 8rem !important
	&--75
		padding: 0 7.5rem !important
	&--70
		padding: 0 7rem !important
	&--65
		padding: 0 6.5rem !important
	&--60
		padding: 0 6rem !important
	&--55
		padding: 0 5.5rem !important
	&--50
		padding: 0 5rem !important
	&--45
		padding: 0 4.5rem !important
	&--40
		padding: 0 4rem !important
	&--35
		padding: 0 3.5rem !important
	&--30
		padding: 0 3rem !important
	&--25
		padding: 0 2.5rem !important
	&--20
		padding: 0 2rem !important
	&--15
		padding: 0 1.5rem !important
	&--10
		padding: 0 1rem !important
	&--5
		padding: 0 .5rem !important

.ml
	&--100
		margin-left: 10rem !important
	&--95
		margin-left: 9.5rem !important
	&--90
		margin-left: 9rem !important
	&--85
		margin-left: 8.5rem !important
	&--80
		margin-left: 8rem !important
	&--75
		margin-left: 7.5rem !important
	&--70
		margin-left: 7rem !important
	&--65
		margin-left: 6.5rem !important
	&--60
		margin-left: 6rem !important
	&--55
		margin-left: 5.5rem !important
	&--50
		margin-left: 5rem !important
	&--45
		margin-left: 4.5rem !important
	&--40
		margin-left: 4rem !important
	&--35
		margin-left: 3.5rem !important
	&--30
		margin-left: 3rem !important
	&--25
		margin-left: 2.5rem !important
	&--20
		margin-left: 2rem !important
	&--15
		margin-left: 1.5rem !important
	&--10
		margin-left: 1rem !important
	&--5
		margin-left: .5rem !important
	&--0
		margin-left: 0 !important
	&--auto
		margin-left: auto

.mx--auto
	margin: 0 auto !important
.mr
	&--100
		margin-right: 10rem !important
	&--95
		margin-right: 9.5rem !important
	&--90
		margin-right: 9rem !important
	&--85
		margin-right: 8.5rem !important
	&--80
		margin-right: 8rem !important
	&--75
		margin-right: 7.5rem !important
	&--70
		margin-right: 7rem !important
	&--65
		margin-right: 6.5rem !important
	&--60
		margin-right: 6rem !important
	&--55
		margin-right: 5.5rem !important
	&--50
		margin-right: 5rem !important
	&--45
		margin-right: 4.5rem !important
	&--40
		margin-right: 4rem !important
	&--35
		margin-right: 3.5rem !important
	&--30
		margin-right: 3rem !important
	&--25
		margin-right: 2.5rem !important
	&--20
		margin-right: 2rem !important
	&--15
		margin-right: 1.5rem !important
	&--10
		margin-right: 1rem !important
	&--5
		margin-right: .5rem !important
	&--0
		margin-right: 0 !important
	&--auto
		margin-right: auto
.mx--auto
	margin-left: auto
	margin-right: auto
// body .crisp-client .cc-tlyw .cc-kxkl .cc-1hqb,
// body .crisp-client .cc-tlyw .cc-kxkl .cc-nsge
//	bottom: 7rem !important
