.login
	+fullwh()
	display: flex
	overflow: hidden
	background-color: #F7F7F7
	.loader-love
		display: inline-block
	a
		&:not(.btn):not(.chrome-btn)
			+br(1)
			box-shadow: 0 0 1rem $dark10
			margin: 2rem
	.bullet-container
		+flex()
		+mbi(1)
		position: relative
		top: -.7rem

	.bullet
		+br(5)
		+sq(.8)
		background-color: #767b94
		flex-shrink: 0
		&.current
			background-color: $blue
			&.error
				background-color: $red
		&.done
			background-color: $green
	.mobile
		display: none
		width: 100%
		@media screen and (max-width: 768px)
			display: flex
			flex-direction: column
			justify-content: center
		.login
			&__logo,
			&__logo--dark
				margin-bottom: 2rem
			&__par__title
				margin-bottom: 4rem
	&__left
		padding: 4rem
		max-width: 48rem
		height: 100%
		background-color: $white
		border-right: .1rem solid $border
		box-shadow: 0 0 5rem $black10
		overflow-y: auto
		@media screen and (max-width: 960px)
			padding: 4rem 2rem
			max-width: 36rem
		@media screen and (max-width: 768px)
			display: none
	&__right
		height: 100%
		overflow-y: auto
		flex-grow: 1
		&.get-email
			@media screen and (min-width: 769px)
				display: flex
				flex-direction: column
				width: 100%
				.login__step
					height: auto
				.mobile
					.login
						&__logo,
						&__logo--dark
							margin-bottom: 2rem
						&__par__title
							margin-bottom: 4rem
		&__content
			width: 100%
			padding: 5rem 6rem
			@media screen and (min-height: 600px)
				height: 100%
			@media screen and (max-width: 768px)
				padding: 3rem
				height: auto
	&__header
		margin-bottom: 4rem
	&__logo,
	&__logo--dark
		max-width: 16rem
		width: 100%
		margin: 0 auto 8rem auto
	&__hero,
	&__hero--dark
		margin-bottom: 4rem
	&__hero--dark,
	&__logo--dark
		display: none
	.par
		font-size: $txt + .4
		margin-bottom: 2rem
		width: 100%
		text-align: center
		line-height: 1.4
	&__par
		color: $blue
		font-size: $txt + .4
		line-height: 1.4
		text-align: center
		margin-bottom: 4rem
		font-weight: 500
		&__title
			+bold()
			color: $black
			font-size: $txt + 1
			margin-bottom: 2rem
	.form
		width: 100%
		.input
			&__label,
			&__item
				text-align: left
	&__form
		+flexCol()
		gap: 1rem
		width: 100%
		margin: auto
		.signup &
			gap: 2rem
		.par
			font-size: 1.6rem
			line-height: 1.4
			text-align: center
		.btn
			justify-content: center !important
		.link-handler
			+flex()
			justify-content: flex-end
			height: 4rem
		&__email-sent
			+flexCol()
			gap: 2rem
			align-items: center
			text-align: center
			.email-sent
				stroke: $green
			.title
				margin-bottom: 0
				color: $green
	&__step
		display: flex
		flex-direction: column
		align-items: center
		color: $black
		&:not(.fullh)
			@media screen and (min-height: 600px)
				justify-content: center
				height: 100%
			@media screen and (max-width: 768px)
				justify-content: flex-start
				height: auto
		&__header
			+flexCol()
			+semibold()
			width: 100%
			align-items: center
			flex-shrink: 0
			font-size: 1.6rem
			color: $blue
			@media screen and (max-width: 768px)
				margin-bottom: 3rem
		&__label
			margin-right: auto
			@media screen and (max-width: 960px)
				margin-right: unset
				margin-bottom: 4rem
			.get-email &
				margin-right: unset
			&.success
				color: $green
		&__body
			width: 100%
			@media screen and (max-width: 860px)
				padding: 0
			@media screen and (max-width: 768px)
				overflow: hidden
				justify-content: flex-start
				height: auto
			&.centered
				text-align: center
				margin-top: 20%
			&.narrow
				max-width: 36rem
				text-align: center
			.loader-wrapper
				margin-bottom: 6rem
			.not-found
				font-size: 2rem
				color: $red
				margin-bottom: 4rem
				font-weight: 400
			h2
				font-size: 3rem
				text-align: center
				line-height: 1.4
				margin-bottom: 4rem
		.login-separator
			+flexAxis()
			height: 6rem
			width: 100%
			font-weight: 800
			position: relative
			margin-bottom: 2rem
			&:before
				content: ''
				width: 100%
				height: 1rem
				border-top: .1rem solid $black10
				position: absolute
				left: 0
				top: calc(50% - .1rem)
				z-index: 1
			&-mask
				+br(10)
				+sq(5)
				+flexAxis()
				background-color: #F7F7F7
				z-index: 2
		.login-extension
			+flexAxis()
			width: 100%
			margin-bottom: 2rem
			.extension-login-btn
				max-width: 30rem
				margin: 2rem 0 0 0
			.loader-wrapper
				margin: 2rem 0 0 0
		.signup-link
			+flex()
			justify-content: center
			gap: .3rem
			font-size: 1.5rem
		.linkedin-sync
			margin-top: 6rem
			width: 26rem
			+flex()
			&-images
				max-width: 6rem
				width: 100%
				flex-shrink: 0
			.loader-bullets
				position: relative
				left: -15rem
