.api
	&__header
		+flexSpread()
		max-height: $listHeight
		height: 100%
		flex-shrink: 0
		border-bottom: .1rem solid $border
		padding: 0 $boardPad
	&__content
		+bgPattern()
		overflow-y: auto
		width: 100%
		height: 100%
		padding: 4rem $boardPad
		position: relative
		&:before
			opacity: .7
			background-size: 30%
	&__link
		+br()
		display: inline-flex
		align-items: center
		border: .1rem solid $black
		align-self: flex-start
		gap: 2rem
		padding: 1rem 2rem
		&__key
			+bold()
			font-size: 1.6rem
			min-width: 33rem
