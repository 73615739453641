.popover
	&__child
		display: inline
	&__content
		+box()
		z-index: 10000
		background-color: $white
		min-height: $buttonHeight
		max-width: 30rem
		position: absolute
		min-width: 16rem
		padding: 1.2rem 1.6rem
		list-style: none
		background-clip: padding-box
		margin: 0
		&__emoji
			width: 35rem
			max-width: 35rem
			padding: 0
			background-color: transparent
			border: none
			box-shadow: none
			&.epr-emoji-variation-picker
				transform: none
				transition: none
		&__gif
			+br()
			width: 35rem
			max-width: 35rem
			padding: 0
			overflow: hidden
		&.lane-actions
			svg
				path
					fill: $dark3 !important
	&__content-box
		+mbib(1.6)
	&__footer
		+mbi()
		+flex()
		justify-content: flex-end
	&__tooltip
		+bold()
		background-color: $black80
		color: $white
		min-width: 0
		margin: -.6rem 0 0 0 !important
		line-height: 1.4
		svg
			path
				stroke: $dark3
				fill: $black80 !important
		&.popover__upgrade
			+textShadow()
			background-color: $blue
			svg
				path
					fill: $blue !important
		&__cursor
			position: relative
			display: flex
			justify-content: center
			align-items: center
			height: 100%
