.add-account
	display: flex
	justify-content: center
	gap: 2rem
	font-size: 1.4rem
	.connections--list &
		max-width: 80rem
		margin-bottom: 8rem
	&-option
		+br()
		gap: 2rem
		flex: 1 1 0px
		padding: 2rem
		border: .1rem solid $black10
		display: flex
		flex-direction: column
		background-color: $white
		max-width: 40rem
		&.inactive
			opacity: .6
		img
			max-width: 80%
			margin: 3rem auto
		.btn
			margin-left: auto
			margin-top: auto

	ul
		padding: 0 0 0 1.6rem
		li
			margin-bottom: .8rem
			font-weight: 700
