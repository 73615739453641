.dashboard
	&__header
		+flex()
		width: 100%
		min-height: $listHeight
		flex-shrink: 0
		border-bottom: .1rem solid $border
	&__content
		height: 100%
		overflow-y: auto
	&__user-account
		display: flex
		align-items: center
		gap: 2rem
		width: 100%
		padding: 1rem 3rem
		background-color: #F5F5F5
		position: relative
		flex-shrink: 0
		border-bottom: .1rem solid $black8
		&-avatar
			+box()
			+avatar(6,0,#FFF)
			+br()
		&-insight
			display: flex
			flex-wrap: nowrap
			gap: 1rem
			margin-left: 3rem
			.box
				margin: 0
				box-shadow: none
				&__value
					font-size: 1.6rem
					margin-bottom: .4rem
				&__label
					font-size: .9rem
		&-name
			+bold()
			font-size: 1.6rem
			line-height: 1.4
		&-position
			+bold()
			font-size: 1.4rem
			line-height: 1.4
			white-space: normal
	&__section
		&-title,
		&-menu,
		&-chart
			+flex()
			width: 100%
			padding: 0 $boardPad
			border-bottom: .1rem solid $border
		&-title
			+flexSpread()
			gap: 3rem
			min-height: 6rem
			background-color: $black1
			.select
				max-width: 30rem

		&-menu
			min-height: 4rem
			gap: 2rem
			background-color: $black4
			.btn--link
				color: $black60
				&.active
					color: $black
					font-weight: 800
		&-chart
			padding: $boardPad
			gap: 3rem
			border-bottom: .1rem solid $border
			min-height: 45rem
			&-empty
				+flexCol()
				position: relative
				width: 100%
				max-width: 70rem
				align-self: stretch
				flex-shrink: 0
				min-height: 53rem
			&-placeholder
				+flexAxis()
				+br()
				width: 100%
				max-width: 70rem
				flex-grow: 1
				flex-shrink: 0
				background-color: $black3
				height: 40rem
			h5
				color: $black
				font-size: 1.2rem
			&__rates
				display: flex
				margin-left: 5%
				gap: 6rem
				flex-grow: 1
				flex-wrap: wrap
				.rate
					align-self: flex-start
					&-value
						+sq(16)
						+br(30)
						+flexAxis()
						border: 1rem solid #2DD08A
						font-size: 4rem
						font-weight: 800
						margin-bottom: 1rem
						&.acceptance
							border-color: #D222F2
						&.no-value
							border-color: $black10
							font-size: 1.3rem
							font-weight: 300
					&-title
						text-align: center
	&__legend
		margin: 4rem 0 4rem 8rem
		display: flex
		gap: 3rem
		font-size: 1.2rem
		flex-shrink: 0
		.dashboard__section-chart-empty &
			margin: 2rem 0 10rem 4rem
		.bullet
			width: 1rem
			height: 1rem
			flex-shrink: 0
		.legend
			+flex()
			gap: 1rem
		.requested
			.dashboard__section-chart-empty &
				color: $black40
			.bullet
				background-color: #3474FF
				.dashboard__section-chart-empty &
					background-color: $black10
		.accepted
			.dashboard__section-chart-empty &
				color: $black40
			.bullet
				background-color: #D222F2
				.dashboard__section-chart-empty &
					background-color: $black10
		.messaged
			.dashboard__section-chart-empty &
				color: $black40
			.bullet
				background-color: #FF2092
				.dashboard__section-chart-empty &
					background-color: $black10
		.answered
			.dashboard__section-chart-empty &
				color: $black40
			.bullet
				background-color: #2DD08A
				.dashboard__section-chart-empty &
					background-color: $black10
