.settings
	display: flex
	.none
		display: none
	.content
		&__sidebar
			&__body
				overflow-y: auto
				&__section
					padding: 1rem 2.4rem
					&:last-child
						padding-bottom: 6rem
	&__header
		+flex()
		min-height: $listHeight
		flex-shrink: 0
		border-bottom: .1rem solid $border
		> *
			+flex()
			height: 100%
			padding: 0 $boardPad
			&:not(:last-child)
				border-right: .1rem solid $border
	&__content
		+fullwh()
		align-content: flex-start
		background-color: $black3
		overflow-y: auto
		padding: 3rem
		.automation-mode &
			background-color: $white
