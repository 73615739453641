.label
	+trans()
	&:not(.label--iconed)
		+flexBtn()
		+br()
		+mbi(.7)
		min-height: 3.4rem
		font-size: $buttonFontSize
		line-height: 1.6
		padding: .6rem 1.2rem
		color: $dark
		margin: .2rem 0
		word-break: break-all
		cursor: pointer
		max-width: 100%
	.btn
		&.btn--link
			&:hover
				.icon
					stroke: $black !important
	&__name
		+ellipsis()
		margin-right: 1.2rem
	&-container
		+br()
		background-color: $white
	&--selectable
		&:hover
			background-color: $blue6
			.label__name
				color: $blue
		&.label--selected
			background-color: $blue10
			.label__name
				color: $blue
				+bold()
			&:hover
				background-color: $blue10
	&--nowrap
		word-break: unset
		white-space: nowrap
	&--small
		&.label
			min-height: $buttonHeightSmall
			font-size: $buttonFontSizeSmall
			line-height: 1.4
			padding: .4rem $padding - .4rem .4rem $padding - .4rem
			.label__name
				margin-right: .6rem
			.btn
				.icon
					stroke-width: .25rem

			&.label--round
				+br(50)
				+sq($buttonHeightSmall)
				padding: 0
				width: $buttonHeightSmall
				min-height: unset
	&--mini
		&.label
			min-height: $buttonHeightMini - .2
			font-size: $buttonFontSizeMini + .1
			line-height: 1.2
			padding: .2rem $padding - .6 .2rem $padding - .6
			.label__name
				margin-right: .5rem
			.btn
				.icon
					+sq(1.1)
					stroke-width: .21rem
			&.label--round
				+br(50)
				+sq($buttonHeightMini)
				padding: 0
				min-height: unset
	&--nano
		&.label
			min-height: auto
			font-size: $buttonFontSizeNano
			color: $black90
			line-height: 1.1
			padding: .4rem .6rem
			&--spacing
				margin-right: .3rem
	&--nobg
		background-color: unset !important
		padding: 0 !important
		&.label--iconed
			+sq(2.4)
			+flexAxis()
	&--blue
		&.label
			background-color: $blue
			color: $white
			.btn
				.icon
					stroke: $white
	&--green-sf
		&.label
			background-color: $green-sf
			color: $white
			.btn
				.icon
					stroke: $white
	&--owner
		background-color: #a638a1
		font-weight: 800
		color: $white !important
